import Image from 'components/Image';
import tfIosApp from '../../images/travelfeed-ios-app.png';
import truvvlLogo from '../../images/truvvl-header-logo-wide.svg';
import tfLogo from '../../images/TravelFeed-logo-horizontal-color.svg';
import menuTranslatorLogo from '../../images/menu-translator.png';
import truvvlSplash from '../../images/truvvl-phone-splash.png';
import appStoreBadge from '../../images/app-store.svg';
import playStoreBadge from '../../images/play-store.svg';
import menuTranslatorMockup from '../../images/menu-translator-app.png';

export default function App() {
  return (
    <>
      <section className="tw-w-full tw-py-12 md:tw-py-24 lg:tw-py-32 tw-bg-primary-500">
        <div className="container tw-px-4 md:tw-px-6">
          <div className="tw-grid tw-items-center tw-gap-6 lg:tw-grid-cols-[1fr_400px] lg:tw-gap-12 xl:tw-grid-cols-[1fr_600px]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4">
              <div className="tw-flex tw-items-center tw-space-x-4">
                <h1 className="tw-text-3xl tw-font-bold tw-tracking-tighter sm:tw-text-5xl tw-text-white">
                  Our Apps
                </h1>
              </div>
              <p className="tw-max-w-[600px] tw-text-white md:tw-text-xl">
                Discover and plan your next adventure with our apps. Download
                now and explore the world at your fingertips.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-w-full tw-py-12 md:tw-py-24 lg:tw-py-32 tw-bg-primary-100">
        <div className="container tw-px-4 md:tw-px-6">
          <div className="tw-grid tw-items-center tw-gap-6 lg:tw-grid-cols-[1fr_400px] lg:tw-gap-12 xl:tw-grid-cols-[1fr_600px]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4">
              <div className="tw-flex tw-items-center tw-space-x-4">
                <h2 className="tw-text-3xl tw-font-bold tw-tracking-tighter sm:tw-text-5xl tw-text-primary-900">
                  <Image
                    priority
                    src={tfLogo}
                    alt="TravelFeed"
                    width={400}
                    height={70}
                    unoptimized
                  />
                </h2>
              </div>
              <p className="tw-max-w-[600px] tw-text-primary-900 md:tw-text-xl">
                Experience the full TravelFeed platform on your smartphone. Get
                unparalleled access to authentic travel stories, insider tips,
                and hidden gems from around the world. Effortlessly manage and
                update your travel blog directly from your phone.
              </p>
              <div className="tw-mt-8 tw-flex lg:tw-mt-0 lg:tw-flex-shrink-0">
                <div className="tw-inline-flex">
                  <a
                    href="https://play.google.com/store/apps/details?id=io.travelfeed.pwa"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={playStoreBadge}
                      alt="Play store"
                      width={250}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
                <div className="tw-ml-3 tw-inline-flex">
                  <a
                    href="https://apps.apple.com/app/travelfeed/id6472209919"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={appStoreBadge}
                      alt="App store"
                      width={221}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
              </div>
            </div>
            <Image
              alt="TravelFeed App Screenshot"
              class="tw-mx-auto tw-aspect-video tw-overflow-hidden tw-object-contain tw-object-center sm:tw-w-full lg:tw-order-last"
              height={910 / 1.7}
              src={tfIosApp}
              width={361 / 1.7}
            />
          </div>
        </div>
      </section>
      <section className="tw-w-full tw-py-12 md:tw-py-24 lg:tw-py-32 tw-bg-primary-500">
        <div className="container tw-px-4 md:tw-px-6">
          <div className="tw-grid tw-items-center tw-gap-6 lg:tw-grid-cols-[400px_1fr] lg:tw-gap-12 xl:tw-grid-cols-[600px_1fr]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4">
              <div className="tw-flex tw-items-center tw-space-x-4">
                <h2 className="tw-text-3xl tw-font-bold tw-tracking-tighter sm:tw-text-5xl tw-text-white">
                  <Image
                    priority
                    src={truvvlLogo}
                    alt="truvvl"
                    width={289}
                    height={70}
                    unoptimized
                  />
                </h2>
              </div>
              <p className="tw-max-w-[600px] tw-text-white md:tw-text-xl">
                Discover a fresh, innovative way to explore travel blogs and
                stories near you with truvvl. Our app transforms travel blogs
                into swipeable stories, making it easier and more engaging to
                discover local insights and experiences. Plus, create and share
                your own stories on the go, capturing moments instantly.
              </p>
              <div className="tw-mt-8 tw-flex lg:tw-mt-0 lg:tw-flex-shrink-0">
                <div className="tw-inline-flex">
                  <a
                    href="https://play.google.com/store/apps/details?id=io.travelfeed.truvvl"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={playStoreBadge}
                      alt="Play store"
                      width={250}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
                <div className="tw-ml-3 tw-inline-flex">
                  <a
                    href="https://apps.apple.com/app/truvvl/id1552253482"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={appStoreBadge}
                      alt="App store"
                      width={221}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
              </div>
            </div>
            <Image
              alt="App Screenshot"
              class="tw-mx-auto tw-aspect-video tw-overflow-hidden tw-object-contain tw-object-center sm:tw-w-full lg:tw-order-last"
              height={1605 / 3}
              src={truvvlSplash}
              width={1080 / 3}
            />
          </div>
        </div>
      </section>
      <section className="tw-w-full tw-py-12 md:tw-py-24 lg:tw-py-32 tw-bg-[#2D3346]">
        <div className="container tw-px-4 md:tw-px-6">
          <div className="tw-grid tw-items-center tw-gap-6 lg:tw-grid-cols-[1fr_400px] lg:tw-gap-12 xl:tw-grid-cols-[1fr_600px]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4">
              <div className="tw-flex tw-items-center tw-space-x-4">
                <h2 className="tw-text-3xl tw-font-bold tw-tracking-tighter sm:tw-text-5xl tw-text-[#F3E4C8] tw-flex tw-items-center">
                  <Image
                    priority
                    src={menuTranslatorLogo}
                    alt="TravelFeed"
                    width={70}
                    height={70}
                  />
                  <span>Menu Translator</span>
                </h2>
              </div>
              <p className="tw-max-w-[600px] tw-text-[#F8F4E9] md:tw-text-xl">
                Never struggle with foreign menus again. Our{' '}
                <a
                  href="https://www.menutranslatorapp.com/"
                  target="_blank"
                  className="tw-underline hover:tw-text-[#F3E4C8] hover:tw-underline"
                >
                  Menu Translator App
                </a>{' '}
                helps you understand and order confidently in restaurants
                worldwide. Simply point your camera at the menu, and get all
                dishes explained in your preferred language.
              </p>
              <div className="tw-mt-8 tw-flex lg:tw-mt-0 lg:tw-flex-shrink-0">
                <div className="tw-inline-flex">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.curlycorn.menutranslator"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={playStoreBadge}
                      alt="Play store"
                      width={250}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
                <div className="tw-ml-3 tw-inline-flex">
                  <a
                    href="https://apps.apple.com/app/menu-translator-app/id6670431055?platform=iphone"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <Image
                      priority
                      src={appStoreBadge}
                      alt="App store"
                      width={221}
                      height={74}
                      unoptimized
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="tw-max-w-[300px] tw-mx-auto">
              <Image
                alt="TravelFeed App Screenshot"
                className="tw-w-full tw-h-auto tw-object-contain tw-object-center lg:tw-order-last"
                height={1510}
                src={menuTranslatorMockup}
                width={746}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <p className="tw-text-sm tw-pt-5 tw-text-gray-400">
          App Store®, iPhone®, and iPad® are trademarks of Apple Inc. Google
          Play and the Google Play logo are trademarks of Google LLC.
        </p>
      </div>
    </>
  );
}
