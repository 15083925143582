import React from 'react';
import App from '../components/About/App';
import Footer from '../components/General/Footer';
import Head from '../components/Header/Head';
import Header from '../components/Header/Header';
import withApollo from '../lib/withApollo';

const AppPage = () => {
  return (
    <>
      <Head
        title="TravelFeed Apps"
        description="Download the TravelFeed app and browse the best travel blogs on the go! Join thousands of travelers in our global travel community and start your own travel blog."
      />
      <Header active="app" subheader="Get our App" />
      <App />
      <Footer />
    </>
  );
};

export default withApollo(AppPage);
